//
// Dark Theme
//
[data-theme="light"] {
  @import "dark-colors";

  // !TODOCSS 임시 적용
  .text-muted {
    color: #000 !important;
  }

  .card .card-body {
    background-color: #fff !important;
  }

  .footer {
    background-color: #191f28 !important;
    color: #6b7684 !important;
  }
}
