.custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  background-size: none;
}

/*******************
SidebarSearch input
*******************/

.input-group-text {
  background-color: $gray-770;
  border: 1px solid $gray-620;
}



/*******************
Search input
*******************/

// input type="search" 커스텀(x버튼 없애기)

// input::-ms-clear,
// input::-ms-reveal{
// 	display:none;width:0;height:0;
// }
// input::-webkit-search-decoration,
// input::-webkit-search-cancel-button,
// input::-webkit-search-results-button,
// input::-webkit-search-results-decoration{
// 	display:none;
// }


.input-search {
  border-right: none;
}

.input-search-icon {
  background-color: $gray-730;
  color: $primary;

  & :hover {
    cursor: pointer;
    background-color: $gray-760;
  }
}

.input-search-cancel {
  border-left: none;
  background-color: $gray-730;
  color: $gray-520;

  & :hover {
    cursor: pointer;
    color: $gray-760;
  }
}


/*******************
Search input
*******************/
// input:-webkit-autofill,
// input:-webkit-autofill:hover,
// input:-webkit-autofill:focus textarea:-webkit-autofill,
// textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
// select:-webkit-autofill,
// select:-webkit-autofill:hover,
// select:-webkit-autofill:focus,
// input:-internal-autofill-selected {
//   box-shadow: 0 0 0px 1000px #45464a inset !important;
//   -webkit-text-fill-color: #fff !important;
// }