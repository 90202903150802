.font-bold {
  font-weight: $font-weight-bold;
}

.font-normal {
  font-weight: $font-weight-normal;
}

.font-light {
  font-weight: $font-weight-light;
}

.font-medium {
  font-weight: $font-weight-medium;
}

.fs-input {
  font-size: 3.2rem !important;

  &-full {
    font-size: 3.2rem !important;
  }

  &-mini-sidebar {
    font-size: 2.3rem !important;
    margin-top: 3rem;
  }

  &-mobile {
    font-size: 2rem !important;
  }

  &::placeholder {
    font-size: 3.2rem !important;
    color: #77787b !important; // $gray-620
  }

  &:focus {
    border-color: #ed7422;
    caret-color: #fff;
  }
}

.overflow-view {
  height: 13rem;
  overflow-y: auto;
}

.overflow-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  white-space: nowrap; // 텍스트에 띄어쓰기가 없을 시 줄넘침 현상
  word-wrap: break-word;
  word-break: break-all;
}

.c-primary {
  color: $primary;
}

.c-dark {
  color: $gray-610;
}

.c-black {
  color: $gray-870;
}

.bg-dark-input {
  background-color: $gray-760;
}

.bg-gray {
  background-color: $gray-740;
}

.bg-dark {
  background-color: #222222 !important;
}

.bg-dark-gray {
  background-color: $gray-770;
}

.bg-purple-light {
  background-color: $purple-light;
}

.text-box-img {
  img {
    max-width: 100%;
  }
}

.text-box {
  word-spacing: 0.3em;
  line-height: 1.7;
}