// 채팅 말풍선 가로 사이즈 
$custom-box-width: 280px;

/*******************/
/*Chat widget*/
/*******************/
.chat-box {
  overflow: auto;
  background-color: $gray-850;
  border-top: 1px solid $gray-780;
}

.chat-list {
  margin: 0px;
  padding: 10px 20px;
  overflow: auto;
  position: relative;

  .left-part {
    width: 260px;
  }

  .chat-item {
    list-style: none;
    display: flex;
    margin-top: 15px;
    // &:first-child {
    //   margin-top: 15px;
    // }

    .chat-img {
      display: inline-block;
      width: 40px;
      vertical-align: top;
      margin-right: 10px;
      img {
        width: 40px;
        border-radius: 100%;
      }
    }

    .chat-content {
      width: calc(100% - 1px);
      display: inline-block;

      .box {
        border-radius: 4px;
        width: inherit;
        max-width: 90%;
        display: inline-block;
        padding: 6px 10px;
        margin-top: 5px;
        margin-bottom: 3px;
        background: $gray-950;
        border-radius: 0 8px 8px;
        word-break: break-all;
        align-self: flex-start;
        text-align: justify;
        color: $text-muted;
      }
    }

    .chat-time {
      display: block;
      font-size: 10px;
      color: $gray-700;
      margin: 5px 0 15px 65px;
    }

    &.odd {
      display: inherit;
      .chat-content {
        text-align: right;
        width: calc(100% - 0px);
      }

      .chat-time {
        text-align: right;
      }

      .box {
        clear: both;
        width: inherit;
        max-width: 90%;
        color: $black;
        background: $white;
        margin-left: 20px;;
        border-radius: 8px 0 8px 8px;
        word-break: break-all;
        align-self: flex-end;
        text-align: justify;
      }
    }

    &.odd + .odd {
      margin-top: 0px;
    }

    &.reverse {
      text-align: right;

      .chat-time {
        text-align: left;
      }

      .chat-content {
        padding-left: 0px;
        padding-right: 15px;
      }
    }
  }
}

.chat-box-footer {
  display: flex;
  padding: 25px 20px;
  background-color: $gray-950;

  .chat-button {
    transform: none;
    border: 0;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  
}

@include media-breakpoint-down(sm) {
  .chat-list {
    .left-part {
      width: 230px;
    }

    .chat-item {
      .chat-content {
        width: calc(100% - 7px);
      }

      .show-left-part {
        &.left-part-open {
          left: 269px;
          top: 73px;
        }
      }
    }
  }
}

.chat-listing {
  .list-group-item {
    .chat-icon {
      display: none;
    }
    &:hover {
      .chat-icon {
        display: block;
      }
    }
  }
}

.i-rotate-45 {
  transform: rotate(45deg);
}
