
 $sidebar-width-full: 250px;
 $mobile-header-height: 46px;

 /*============================================================== 
  main-wrapper
 ============================================================== */

 #main-wrapper {
  width: 100%;
}

.page-wrapper {
  position: relative;
  transition: 0.2s ease-in;
  display: none;
}

.page-breadcrumb {
  padding: 30px 30px 0px;
}

// PC 
@media (min-width: 1025px) {
  #main-wrapper[data-layout=vertical][data-sidebartype=full] {
    .page-wrapper {
      margin-left: $sidebar-width-full;
    }
  }
}

// TABLET
@media (min-width: 767.98px) and (max-width: 1024px) {
  .page-wrapper {
    margin-left: 0;
  }
}


// PC & TABLET
@media (min-width: 767.98px) {
  #main-wrapper[data-layout=vertical][data-sidebartype=full] {
    .sidebar {
      width: $sidebar-width-full;
    }
  }

  #main-wrapper[data-layout=vertical][data-sidebartype=mini-sidebar] {
    .sidebar {
      width: 0;
      overflow: hidden;
    }
  }
}

// MOBILE
@media (max-width: 767.98px) {
  #main-wrapper {
    .sidebar {
      padding-top: $mobile-header-height + 10px;
      width: 0;
      overflow: hidden;
    }
  }

  #main-wrapper.show-sidebar {
    .sidebar {
      width: $sidebar-width-full;
    }
  }
}
