// Sidebar
// !TODOCSS 변수명 정리하기 (_variables.scss로 따로 생성하여)
$sidebar-link-padding: 17px 13px;
$sidebar-icon-size: 20px;
$sidebar-first-level-padding: 0 0 10px 0;
$sidebar-first-level-link-padding: 10px 15px;
$sidebar-first-level-icon-size: 14px;
$sidebar-second-level-padding: 0;
$sidebar-bottom-height: '40px';
$shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
$sidebar-text: #77787b;
$sidebar-icons: #fff;
$gray-950: #1b1c1e !default; 

/*******************
Main sidebar
******************/

.sidebar {
  position: fixed;
  width: $sidebar-width-full;
  height: 100%;
  top: 0px;

  z-index: 11;
  box-shadow: $shadow;
  transition: 0.2s ease-in;

  background-color: var(--sidebar-bg-color);
  color: var(--main-font-color);
}

/*******************
sidebar navigation
******************/

/**
 * 스크롤 모양 제거
 */

.scroll-sidebar {
  height: calc(100% - 20px);
  overflow: auto;

  -ms-overflow-style: none;   /* IE and Edge */
  scrollbar-width: none;      /* Firefox */
}

/**
 * 스크롤 모양 제거
 */
.scroll-sidebar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}

.sidebar-nav {
  ul {
    .sidebar-item {
      width: $sidebar-width-full;
      font-size: 1.7rem;
      .sidebar-link {
        color: $sidebar-text;
        padding: $sidebar-link-padding;
        display: flex;
        white-space: nowrap;
        align-items: center;
        line-height: 25px;
        opacity: 0.6;
        font-weight: 400;
        cursor: pointer;
        svg {
          color: $sidebar-text;
          &:hover {
            opacity: 1;
            color: var(--always-white);
          }
        }
        i {
          font-style: normal;
          width: 35px;
          line-height: 25px;
          font-size: $sidebar-icon-size;
          color: $sidebar-icons;
          display: inline-block;
          text-align: center;
        }
        &:hover {
          opacity: 1;
          color: var(--always-white);
        }
      }
      &.selected > .sidebar-link {
        background: var(--dark);
        opacity: 1;
        color: var(--primary);
        font-weight: 500;
        background-color: inherit;
        svg {
          color: var(--primary);
        }
      }
      &:hover {
        background: rgba(0, 0, 0, 0.07);
      }
      /*******************
        First level
      ******************/
      .first-level {
        padding: $sidebar-first-level-padding;
        .sidebar-item {
          background: unset;
          &:hover {
            background: unset;
          }
        }
        .selected > .sidebar-link {
          background: unset;
        }
        .sidebar-item.active > .sidebar-link {
          opacity: 1;
          font-weight: 500;
        }
        .sidebar-item .sidebar-link {
          padding: $sidebar-first-level-link-padding;
          i {
            font-size: $sidebar-first-level-icon-size;
            visibility: hidden;
          }
        }
      }
      .second-level .sidebar-item {
        padding: $sidebar-second-level-padding;
        background-color: $gray-950;
        font-size: 15px;
      }
    }
    /*******************
        Small cap
    ******************/
    .nav-small-cap {
      font-size: 12px;
      padding: $sidebar-link-padding;
      white-space: nowrap;
      display: flex;
      align-items: center;
      line-height: 30px;
      margin-top: 10px;
      color: $sidebar-text;
      opacity: 1;
      text-transform: uppercase;
      width: 100%;
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      i {
        line-height: 30px;
        margin: 0 5px;
      }
    }
  }
}

/*******************
 common m-p removed
******************/
.sidebar-nav {
  ul {
    margin: 0px;
    padding: 0px;
    li {
      list-style: none;
    }
  }
}
.collapse.in {
  display: block;
}

.sidebar-link {
  svg {
    font-style: normal;
    width: 35px;
    line-height: 25px;
    font-size: 20px;
    color: #fff;
    display: inline-block;
    text-align: center;
    margin-right: 0.1rem;
  }
}

.sidebar-button {
  margin-top: 20px;
  margin-bottom: 20px;
}

.scroll-sidebar {
  padding-bottom: $sidebar-bottom-height;
}

.sidebar-bottom {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: $sidebar-bottom-height;
}

.mini-sidebar-btn {
  position: fixed;
  width: $mobile-header-height;
  height: $mobile-header-height;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--dark);
}


.sidebar-icon:hover {
  color: var(--primary);
}

.mobile-header {
  position: fixed;
  width: 100%;
  height: $mobile-header-height;
  z-index: 12;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--main-bg-color);
  box-shadow:0 1px 5px 2px rgba(0,0,0,.2);

  .sidebar-icon-box {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translate(0, -50%);
  }

  svg + svg {
    margin-left: 12px;
  }
}