
/*******************
 competition Card
*******************/
.competition-card-group-wrapper {
  background-color: $gray-760;
  padding-top: 10px;
  min-height: 586px;

  .competition-card {
    padding: 0 4rem;
    padding-top: 2rem;

    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 20px;
    }

    @media (max-width: 767.98px) {
      padding: 0 2rem;
    }
  }

  .competition-col {
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 0;
    border-bottom: 1px solid $gray-740;

    
    .text-box {
      margin-left: 15px;
    }

    @media (max-width: 767.98px) {
      padding: 0;

      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
  }

}

.competition-border-top {
  border-top: 1px solid $gray-740;
  padding-top: 20px;
}

.competition-border-bottom {
  border-bottom: 1px solid $gray-740;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

/*******************
 팀 메인 이미지
*******************/
.background-banner {
  width: 100%;
  height: 21rem;
  overflow: hidden;
  min-height: 21rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}


/*******************
 Footer
*******************/

.footer {
  position: relative;
  padding: 25px;
  background-color: $primary;
  color: $gray-870;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;

  .underline {
    color: $gray-870;
    padding-bottom: 3px;
    border-bottom: 2px solid $gray-870;
    font-size: 13px !important;
    font-weight: 600 !important;

    &:hover {
      color: #fff;
      border-color: #fff;
    }
  }

  .logo-content {
    display: flex;
    align-items: center;
    margin-bottom: 14px;

    .logo {
      background: url('../../../../../images/logos/logo-black-1.png') no-repeat;
      background-size: contain;
      width: 40px;
      height: 40px;
    }

    .logo-text {
      margin-left: 10px;
      font-size: 20px;
      font-weight: 600;
      color: $gray-870;
    }
  }

  .box {
    display: flex;
  }

  .line {
    color: $body-bg;
    font-size: 10px;
    font-weight: 100;
    padding: 0 5px;
  }

  .copyright {
    display: inline-block;
    color: $body-bg;
    font-size: 10px;
    font-weight: 400;
  }

}

// 대회카테고리 NavBar (메인/진행/참가자/대진표) - 모바일 일 때
.competition-nav-default {
  position: initial;
}

// 대회카테고리 NavBar (메인/진행/참가자/대진표) - sidebar 존재 하지 않을 때
.competition-nav-wide {
  position: fixed;
  top: 0;
  width: 97%;
  z-index: 2;
  margin-top: 0 !important;
  
  @media (min-width: 450px) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 87%;
    flex: 0 0 87%;
    max-width: 87%;
    top: 3rem;
  }

  @media (min-width: 500px) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91%;
    flex: 0 0 91%;
    max-width: 91%;
    top: 3rem;
  }
  

  @media (min-width: 768px) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 97%;
    flex: 0 0 97%;
    max-width: 97%;
    top: 0;    
  }

} 

// 대회카테고리 NavBar (메인/진행/참가자/대진표) - sidebar 존재 할 때
.competition-nav-sidebar {
  position: fixed;
  top: 0;
  left: 250px;
  width: calc(100% - 250px);
  z-index: 3;

  & .nav-link {
    height: 46px !important;
  }
}

#main-wrapper[data-sidebartype=mini-sidebar] {
  .competition-nav-sidebar {
    left: 46px;
    width: calc(100% - 46px);
  }
}